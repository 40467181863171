// Code generated by Slice Machine. DO NOT EDIT.

import dynamic from 'next/dynamic';

export const components = {
  bot_credits: dynamic(() => import('./BotCredits')),
  bot_info_slice: dynamic(() => import('./BotInfoSlice')),
  contact: dynamic(() => import('./Contact')),
  disign_faq: dynamic(() => import('./DisignFaq')),
  faq: dynamic(() => import('./Faq')),
  feature_screenshot: dynamic(() => import('./FeatureScreenshot')),
  features: dynamic(() => import('./Features')),
  home_info: dynamic(() => import('./HomeInfo')),
  homebanner: dynamic(() => import('./Homebanner')),
  image_block: dynamic(() => import('./ImageBlock')),
  plan_feature: dynamic(() => import('./PlanFeature')),
  pricing: dynamic(() => import('./Pricing')),
  pricing_plan_detils: dynamic(() => import('./PricingPlanDetils')),
  start_info: dynamic(() => import('./StartInfo')),
  testimonial: dynamic(() => import('./Testimonial')),
  text: dynamic(() => import('./Text')),
  title_description: dynamic(() => import('./TitleDescription')),
  why_swiftask_id_difference: dynamic(
    () => import('./WhySwiftaskIsDifference'),
  ),
};
